@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

body {
  overflow-x: hidden;
}

.font-600 {
  font-weight: 600 !important;
}
.font-500 {
  font-weight: 500 !important;
}
.font-400 {
  font-weight: 400 !important;
}
.font-700 {
  font-weight: 700 !important;
}
.sidenav---sidenav---_2tBP {
  background: #f5f7f8 !important;
  border: none !important;
  color: white !important;
  width: 0% !important;
  position: fixed !important;
  height: 91% !important;
}
body {
  background: #f5f7f8;
  font-family: "Quicksand", sans-serif !important;
}

.font-sans {
  font-family: "Quicksand", sans-serif !important;
}
.toast {
  width: 100% !important;
  max-width: none !important;
}
/* .modal-dialog {
  max-width: 1390px !important;
  top: -24px !important;
} */
.economic {
  font-family: "Quicksand", sans-serif;
}

.payment-details {
  padding: 0 20px;
  font-size: 14px;
}

.text-xs {
  font-size: 14px;
}

.form-control-contact {
  font-weight: bold;
}

.form-control {
  box-shadow: rgb(191 195 207) 3px 3px 6px inset,
    rgb(255 255 255) -3px -3px 6px inset;
  background: #e2e8f0;
  border-radius: 7px;
}
.date {
  width: 96%;
}

.header-t {
  width: 100%;
  text-align: left !important;
  font-weight: bold;
}
.PromoButton {
  width: 30% !important;
  border: 1px solid #d5d5d5 !important;
  background: #019eff !important;
  color: white !important;
  border-radius: 0px !important;
}

.inputGetPromo form {
  width: 100%;
}
.inputGetPromo {
  width: 100% !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  box-shadow: rgb(191 195 207) 3px 3px 6px inset,
    rgb(255 255 255) -3px -3px 6px inset !important;
  border-radius: 7px !important;
  border-color: #bebebe !important;
  background-color: transparent !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #6f6f6f !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #6f6f6f !important;
}
.rdrDefinedRangesWrapper {
  display: none;
}

.daterange {
  box-shadow: rgb(191 195 207) 3px 3px 6px inset,
    rgb(255 255 255) -3px -3px 6px inset;
  background: #e9e9e9;
  border-radius: 7px;
  color: rgb(73, 73, 73);
  font-weight: 600;
}

.daterange:focus {
  box-shadow: rgb(191 195 207) 3px 3px 6px inset,
    rgb(255 255 255) -3px -3px 6px inset;
  background: #e9e9e9;
  border-radius: 7px;
}
.MuiFormControl-root {
  width: 100% !important;
  max-width: none !important;
  /* box-shadow: rgb(191 195 207) 3px 3px 6px inset, rgb(255 255 255) -3px -3px 6px inset; */
  background: #ffffff;
  border-radius: 5px;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 2px solid transparent !important;
  border-radius: 20px !important;
}

.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root {
  display: none !important;
}

.round-trip input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
}

.design {
  box-shadow: rgb(176, 180, 190) 3px 3px 6px inset,
    rgb(255 255 255) -3px -3px 6px inset;
  background: #e9e9e9;
  border-radius: 7px;
  color: rgb(51, 51, 51);
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
  position: fixed !important;
  min-width: 227px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.engines {
  box-shadow: 0px 1px 3px 0px rgb(60 64 67 / 30%),
    0px 4px 8px 3px rgb(60 64 67 / 15%);
  margin: 0 16px;
  padding: 58px;
  position: relative;
}
.engines-new {
  position: relative;
  padding: 24px 122px;
  background-image: linear-gradient(to left, #f15b2f47, #f15b2f47) !important;
}
.m-0 {
  margin: 0;
}

.engines-new .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px 17px;
  font-size: 16px;
}

.border-radius-32 {
  border-radius: 32px;
}

.engine-heading {
  font-weight: 700;
  font-size: 35px;
  color: #000 !important;
}

.up {
  background: #f5f7f8 !important;
  /* box-shadow: 3px 3px 6px hsl(225, 14%, 78%), -3px -3px 6px #fff !important; */
  border: none !important;
}
.ups {
  background-image: linear-gradient(to left, #ea836414, #f15b2f47) !important;
}
.upss {
  background: #f5f7f8 !important;
}

.upss:hover {
  background-image: linear-gradient(to left, #ea836414, #f15b2f47) !important;
}
.down {
  background: white !important;
  /* box-shadow: inset 3px 3px 6px #bfc3cf,inset -3px -3px 6px #fff !important; */
}
.btn-shivam {
  background: transparent
    linear-gradient(276deg, rgba(255, 81, 0, 0.898), #e13535) 0 0 no-repeat
    padding-box !important;
  color: white !important;
  /* box-shadow: 3px 3px 3px #cecece, -3px -3px 3px #fff !important; */
  border-radius: 8px !important;
  height: 45px;
  font-size: 1rem !important;
}
.btn-shiv {
  background: linear-gradient(to bottom right, #f3f3f3, #fff) !important;
  color: rgba(7, 30, 84, 0.3) !important;
  box-shadow: 3px 3px 3px #cecece, -3px -3px 3px #fff !important;
  border-radius: 30px !important;
}
.foot {
  background: transparent
    linear-gradient(276deg, rgba(255, 81, 0, 0.898), #e13535) 0 0 no-repeat
    padding-box !important;
  color: white !important;
  width: 300px;
  /* box-shadow: 3px 3px 3px #cecece,-3px -3px 3px #fff !important; */
}

.footer {
  background: #333 bottom no-repeat;
  background-size: contain;
}

.amount {
  background: transparent
    linear-gradient(276deg, rgba(255, 81, 0, 0.898), #e13535) 0 0 no-repeat
    padding-box !important;
  color: white !important;
}
.sidenav---navtext---1AE_f {
  width: 100% !important;

  border: none !important;

  border-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  color: #2e2e2e !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  margin-top: 16px !important;
}

/* .sidenav---navtext---1AE_f:hover{
    color: white !important;
} */
.modal-getfare .modal-dialog {
  width: auto !important;
  height: 100vh;
  justify-content: center;
  display: flex;
  align-items: center;
}

.modal-getfare .btn-close {
  position: absolute;
  top: 12px;
  right: 9px;
}

.modal-getfare .modal-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flight_details_modal {
  max-width: 800px;
}

.modal-getfare .modal-title i {
  color: rgba(255, 81, 0, 0.898);
  font-size: 25px;
  border: 1px solid rgba(255, 81, 0, 0.898);
  border-radius: 50px;
  padding: 10px;
}
.modal-getfare .modal-title h3 {
  font-weight: 700;
}
.modal-getfare .modal-title p {
  font-size: 14px;
  text-align: center;
}

.modal-getfare .modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.modal-getfare .modal-body input {
  box-shadow: 0px 0px 20px 0px rgb(133 143 173 / 10%);
  border: 1px solid #d5d5d5;
  width: 75%;
  height: 49px;
  padding: 10px;
  border-radius: 8px 0 0 8px;
}
.modal-body-new {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.modal-getfare .modal-dialog .modal-content {
  padding: 29px 11px;
  border-radius: 10px;
}
.modal-getfare .modal-body a {
  font-size: 13px;
  margin-top: 15px;
  color: rgba(255, 81, 0, 0.898);
}

.sign-but-get {
  width: 35%;
  height: 48px;
  border-radius: 0 8px 8px 0 !important;
}

.font-14 {
  font-size: 14px;
}
.sidenav---icon-bar---u1f02 {
  background-color: black !important;
}
.navigate:hover {
  background: transparent
    linear-gradient(276deg, rgba(255, 81, 0, 0.898), #e13535) 0 0 no-repeat
    padding-box !important;
  color: white !important;
}
.navigates:hover {
  border: 1px solid rgba(255, 81, 0, 0.898);

  color: white !important;
  box-shadow: none !important;
}
.sidenav---sidenav-navitem---uwIJ-::after {
  background: transparent
    linear-gradient(276deg, rgba(13, 53, 148, 0.898039), #35d3e1) 0 0 no-repeat
    padding-box !important;
  color: white !important;
}

.sidenav---sidenav-nav---3tvij
  sidenav---
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---highlighted---oUx9u
  > .sidenav---navitem---9uL5T::after {
  background: red !important;
}
.text-sh {
  font-size: 10px !important;
  font-weight: 600;
}

.carousel .carousel-control-prev-icon {
  background-image: none !important;
}
.carousel .carousel-control-next-icon {
  background-image: none !important;
}
.right {
  right: 40% !important;
}
.left {
  right: 20% !important;
  text-align: right !important;
}
/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding-top: 10px !important;
  font-weight: 800 !important;
  color: #000000 !important;
  font-size: 14px !important;
  padding-left: 30px !important;
  border: 2px white solid !important;
  box-shadow: 0px 0px 6px #d9d9d9 !important;
} */
.MuiOutlinedInput-input ::placeholder {
  color: #000000 !important;
}

.inputbox {
  background: transparent
    linear-gradient(276deg, rgba(0, 76, 255, 0.898), #0b73eb) 0 0 no-repeat
    padding-box !important;
}
.toop {
  background: transparent
    linear-gradient(276deg, rgba(255, 145, 2, 0.986), #e13535) 0 0 no-repeat
    padding-box !important;
}

.sidenav---sidenav-toggle---1KRjR {
  position: absolute !important;
  top: 50px !important;
  left: 430px !important;
}

.date::placeholder {
  color: black !important;
}
.sidenavd:hover {
  background: transparent
    linear-gradient(276deg, rgba(255, 145, 2, 0.986), #e13535) 0 0 no-repeat;
  color: white;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.offcanvas-start {
  width: 250px !important;
}
.offcanvas-backdrop.show {
  opacity: 0 !important;
}
.dataResult {
  max-width: 100%;
  max-height: 400px;
  overflow-y: scroll;
}

.bg-fafa {
  background-color: #f2f2f2;
}

.margin-top-113 {
  margin-top: -113px;
}

.dataItem {
  color: black;
}
.dataItem:hover {
  background: rgb(233, 233, 233);
  color: rgb(0, 0, 0);
}

#selecteds:hover {
  border: none !important;
}

.city:focus {
  outline: 2px solid rgb(255, 255, 255);
}

.promo-d1 {
  display: flex;
  flex-direction: row;
}

.promo {
  border-radius: 8px;
  margin: 50px 0 10px;
  box-shadow: 0px 0px 20px 0px rgb(133 143 173 / 10%);
  position: relative;
  border: 1px solid #f2f2f2;
  height: 195px;
}

.footer-bottom-pd {
  padding-bottom: 250px;
}
.padding-0 {
  padding: 0 !important;
}
.Small_Txt p {
  font-weight: normal;
  font-size: 15px;
  color: #000;
  font-weight: 500;
}
.promo .upper {
  padding: 29px 29px 3px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1em;
  color: #858fad;
  font-weight: 800;
  flex-direction: column;
}
.promo #pageviews i {
  margin-right: 15px;
}
.promo .upper .left {
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.container .upper .right {
  align-items: center;
  display: flex;
}
.promo .upper .right .price {
  color: #293356;
  font-size: 2.7em;
  margin-right: 5px;
}
.promo .toggle-container {
  text-align: right;
  color: #858fad;
  font-size: 0.95em;
  padding: 40px;
}
.promo .toggle-container .show {
  display: none;
}
.promo .toggle-container .switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
  margin: 0 15px;
}
.promo .toggle-container .switch .slide {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cdd7ee;
  transition: 0.2s;
}
.promo .toggle-container .switch .slide:hover {
  background-color: #a5f3eb;
}
.promo .toggle-container .switch .slide:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.promo .toggle-container .switch .slide.round {
  border-radius: 34px;
}
.promo .toggle-container .switch .slide.round:before {
  border-radius: 50%;
}
.promo .toggle-container .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.promo .toggle-container .switch input:checked + .slide {
  background-color: #10d5c2;
}
.promo .toggle-container .switch input:focus + .slide {
  box-shadow: 0 0 1px #10d5c2;
}
.promo .toggle-container .switch input:checked + .slide:before {
  transform: translateX(18px);
}
.promo .toggle-container .discount {
  background-color: #feece7;
  color: #ff8c66;
  font-size: 0.85em;
  padding: 1px 8px;
  border-radius: 15px;
  margin-left: 6px;
}
.promo .bottom {
  padding: 0px 40px 20px 20px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.promo-dl {
  display: flex;
}
.promo .bottom .Small_Txt p {
  margin-left: 20px;
}
.promo .bottom ul li {
  color: #858fad;
  margin: 10px 0;
  list-style-image: url(https://raw.githubusercontent.com/milenig/fend-mentor_pricing-component/3ccfd6b18e87377a950915288ef64c896969bf62/images/icon-check.svg);
}
.promo .bottom ul li span {
  margin-left: 10px;
}
.promo .bottom button {
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;
  background-color: #293356;
  color: #bdccff;
  padding: 1px 23px;
  border-radius: 0 60px 60px 0 !important;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 8px;
  height: 45px;
  font-size: 15px !important ;
}

.Login-wrapp {
  margin-top: 0px;
}
.Login-button i {
  color: #fff !important;
  margin-right: 6px;
  background: #ff5100;
  padding: 7px;
  border-radius: 50px;
}

.Login-button {
  border: 3px solid rgba(255, 81, 0, 0.898);
  font-weight: bold;
  border-radius: 6px;
  /* margin-top: 12px; */
  padding: 6px 21px !important;
  height: 50px;
  display: block;
  font-size: 16px;
}
.font-ad {
  font-size: 30px;
  margin-top: 6px;
}

.bg-orange-light {
  background-color: #fff6ee;
}

.promo .bottom button:hover {
  color: white;
}

.promo input:focus-visible {
  border: #e13535;
}
.promo input {
  padding: 0 15px;
}

.promo #pageviews {
  font-size: 1.3rem;
  color: #e13535;
}
.promo .upper h2 {
  font-size: 1.5rem;
}

.promo input {
  background-color: white;
  border-radius: 60px 0 0 60px;
  /* margin: 50px 0 10px; */
  box-shadow: 0px 0px 20px 0px rgb(133 143 173 / 10%);
  position: relative;
  width: 66%;
  height: 56px;
  border: 1px solid #d5d5d5;
  height: 48px;
}
.slider {
  width: 550px;
  padding: 0 40px;
}
.slider input {
  background: linear-gradient(
    to right,
    #10d5c2 0%,
    #10d5c2 50%,
    #eaeefb 50%,
    #eaeefb 100%
  );
  border-radius: 8px;
  height: 8px;
  width: 100%;
  border-radius: 4px;
  outline: none;
  transition: all 450ms ease-in;
  -webkit-appearance: none;
}
.slider input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: url(https://raw.githubusercontent.com/milenig/fend-mentor_pricing-component/3ccfd6b18e87377a950915288ef64c896969bf62/images/icon-slider.svg)
    #10d5c2 center no-repeat;
  box-shadow: 0px 10px 20px 0px rgba(16, 213, 194, 0.7);
  cursor: pointer;
  position: relative;
  transition: all 0.2s;
}
.slider input::-webkit-slider-thumb:hover {
  background: url(https://raw.githubusercontent.com/milenig/fend-mentor_pricing-component/3ccfd6b18e87377a950915288ef64c896969bf62/images/icon-slider.svg)
    #1be6d1 center no-repeat;
}
.slider input::-webkit-slider-thumb:active {
  cursor: grabbing;
  background: url(https://raw.githubusercontent.com/milenig/fend-mentor_pricing-component/3ccfd6b18e87377a950915288ef64c896969bf62/images/icon-slider.svg)
    #0dbead center no-repeat;
}

@media only screen and (max-width: 610px) {
  .copyright-small {
    text-align: center;
  }
  .copyright-small img {
    filter: brightness(0) invert(1);
  }

  .img-fluid {
    margin: 0 auto 15px;
  }

  .footer-bottom-pd {
    padding-bottom: 50px;
  }
  .title {
    text-align: center;
  }
  .title .hide {
    display: block;
  }

  .promo .upper {
    flex-direction: column;
  }

  .promo .slider {
    width: 100%;
  }

  .discount .hide {
    display: none;
  }

  .bottom {
    flex-direction: column;
  }
  .bottom ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  .bottom button {
    width: 200px;
  }
}
@media only screen and (max-width: 400px) {
  .promo .toggle-container {
    text-align: center;
  }
  .promo .toggle-container .show {
    display: block;
  }
}

flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.getapp {
  border: 1px solid #d5d5d5;
  width: 65%;
  height: 52px;
  border-radius: 60px 0 0px 60px;
  padding: 0 20px;
}

.getapp-btn {
  border: 1px solid rgba(255, 81, 0, 0.898);
  color: rgba(255, 81, 0, 0.898);
  border-radius: 0px 60px 60px 0px !important;
  height: 52px;
  width: 22%;
}

.get-app-wrapper h2 {
  color: #000;
}

.started {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
.app-btn li {
  display: inline-block;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.app-btn .download {
  width: 200px;
  height: 75px;
  background: #fff;
  float: left;
  border-radius: 5px;
  position: relative;
  color: #000;
  cursor: pointer;
  border: 1px solid #000;
  margin-bottom: 12px;
}

.app-btn .download > .fa {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.app-btn .download img {
  width: 41px;
  margin-top: 18px;
  margin-left: 12px;
}

.df,
.dfn {
  position: absolute;
  left: 70px;
}

.df {
  top: 13px;
  font-size: 0.68em;
}

.dfn {
  top: 28px;
  font-size: 1.08em;
}
.dropdown-menu {
  min-width: 35rem !important;
  top: 10px !important;
  border: 1px solid rgb(207, 207, 207) !important;
  /* padding-right:20px !important;
  padding-left: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important; */
  box-shadow: 0px 0px 10px rgb(202, 202, 202) !important;
  transform: translate(2.444px, 43.4444px) !important;
}
.dropdown-item:hover {
  background-color: white !important;
}
.btn-language {
  box-shadow: 0px 0px 10px rgb(160, 160, 160);
  border-radius: 5px;
  border: 0px solid white;
  font-size: 20px !important;
  padding-left: 20px;
  padding-right: 20px;
  background: transparent
    linear-gradient(276deg, rgba(255, 81, 0, 0.898), #e13535) 0 0 no-repeat
    padding-box !important;

  width: 100%;
  font-weight: 900;
  color: rgb(255, 255, 255);
}
.btn-language:hover {
  background: transparent
    linear-gradient(276deg, rgba(214, 48, 48, 0.898), #f88810) 0 0 no-repeat
    padding-box !important;
  color: white !important;
  color: white;
  font-weight: 900;
}

.btn-currency {
  border: 0px solid white;
  box-shadow: 0px 0px 10px rgb(190, 190, 190);
  width: 100%;
  border-radius: 5px;
  font-weight: 900;
}
.btn-currency:hover {
  background: transparent
    linear-gradient(276deg, rgba(255, 81, 0, 0.898), #e13535) 0 0 no-repeat
    padding-box !important;
  color: white !important;

  font-weight: 900;
}
.select-language {
  background: transparent
    linear-gradient(276deg, rgba(255, 81, 0, 0.898), #e13535) 0 0 no-repeat
    padding-box !important;
  color: white !important;
}
.ps-relative {
  position: relative;
}

.label-font {
  font-weight: bold;
  font-size: 20px;
  font-weight: bold;
  font-size: 16px;
  position: absolute;
  top: 9px;
  z-index: 9;
  left: 13px;
}
.return-font {
  color: #9aa0a6;
  margin-left: -12px !important;
}

.border-right1 {
  border-right: 1px solid #d5d5d5;
}
.search-engine-in {
  border: 2px solid #e7e7e7;
  color: #5f6368 !important;

  border-radius: 9px;
  height: 90px;
  background-color: #fff;
  position: relative;
}

.se-pd {
  padding: 24px 15px 24px 40px;
}
.De-pd {
  padding: 2px 15px;
}

.De-pd .label-font {
  position: relative;
}
.global-search button {
  width: 300px;
}

.Disable-react {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
  width: 134px;
  line-height: 18px;
}

.b-right1 {
  border-right: 1px solid #d5d5d5;
}

.depature-return span.label-font {
  margin-left: -1px !important;
}

.global-search {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.city-Name {
  font-weight: 700;
  font-size: 18px;
}

.passanger-group {
  width: 300px;
}
.class_group {
  width: 200px;
}

.arrow-right-left {
  position: absolute;
  left: 410px;
  top: 21px;
  z-index: 9;
  border: 1px solid #e7e7e7;
  border-radius: 50px;
  padding: 13px;
  box-sizing: border-box;
  height: 49px;
  background: #fff;
}
.arrow-right-left img {
  width: 22px;
}

.grid-gap {
  grid-gap: 15px;
}
.padding-0 {
  padding: 0 20px;
}

.passanger-auto {
  background: #f2f2f2;
  padding: 0 16px;
  border-radius: 9px;
  margin-right: 22px;
}

.Heade-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}
.date-card {
  font-size: 12px;
}
.Heade-name span {
  font-weight: 700;
  color: #e13535;
}

.card-border {
  border: 1px solid #d5d5d5;
}
.heading-re {
  margin: 10px 0;
  font-weight: 700;
  font-size: 20px;
}
.card-recent-search {
  background: #ffefe6;
  border-radius: 3px;
  padding: 1px 9px;
}

.recent-search {
  padding: 20px 6px;
  border-radius: 16px;
}

.fl-right {
  float: right;
}

.passH h1 {
  font-size: 16px;
  font-weight: 700;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 18px;
}

.trav-1 {
  font-size: 19px;
  padding-left: 25px;
  color: #000;
  font-weight: bold;
  margin-bottom: 40px;
}

.new-policy {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  font-size: 15px;
  padding: 7px 10px;
  width: 100%;
}

.inputGetPromo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.gooter-svg {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.footer-set {
  position: relative;
  padding-bottom: 217px;
}

.travomint-bg {
  background-color: #e5e5e5;
}

.usecode {
  margin-top: 15px;
}

.carousel-item img {
  width: 60px;
}
.carousel-item h2 {
  font-size: 23px;
  font-weight: 700;
}

.new-One {
  width: 71px;
  background: #000;
  border-radius: 5px;
  padding: 1px;
}

.carousal_new ul li {
  font-size: 14px;
  text-align: left;
}

.dl {
  margin-top: 65px;
  top: -70px;
}
.wp {
  width: 100%;
  padding: 0px;
}

.recent-search {
  position: absolute;
  bottom: 31px;
}

.next-carosal .carousel-control-prev {
  left: -51px;
  height: 100%;
  background: #fff;
  opacity: 1;
  width: 28px;
  left: 1px;
  border-radius: 8px 19px 30px 21px;
}

.next-carosal .carousel-indicators {
  position: absolute;
  bottom: -53px;
}
.next-carosal .carousel-control-next {
  height: 100%;
  background: #fff;
  opacity: 1;
  width: 28px;
  right: 1px;
  border-radius: 8px 19px 30px 21px;
}

.carousal_new dl ul {
  margin-left: 0;
}

.next-carosal .carousel-control-prev .carousel-control-prev-icon::after {
  font-size: 0.8rem !important;
}
.next-carosal .carousel-control-next .carousel-control-next-icon::after {
  font-size: 0.8rem !important;
}

.next-carosal .carousel {
  height: 100%;
}

.next-carosal .carousel-inner {
  padding: 34px 41px;
}

.travomint-bg {
  font-size: 14px;
  padding: 6px 10% 0;
  width: 100%;
  display: block;
  margin-top: 45px;
}

.travomint-bg span {
  font-size: 16px;
}
.travomint-bg p {
  font-size: 14px;
  margin-bottom: 0 !important;
}

.new-policy a {
  color: #e13535;
}

.phone_img {
  width: 67% !important;
}

.qr-code {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.qr-code-img {
  width: 300px;
}
.usecode span {
  font-weight: bold;
  color: #e13535;
}

.recent-search div.user_name {
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-size: 12px;
}

.flight_Details .modal-dialog {
  max-width: 1150px !important;
}
.flight_Details .modal-dialog .modal-title {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 15px;
}

.flight-details-payment .w-full {
  padding: 14px 21px;
}
.info-head-payment {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background: #dcfbd0 !important;
}
.info-head-payment .payment-info-head {
  padding: 15px;
  width: 100%;
  font-size: 18px;
}
.info-head-payment .payment-info-head h4 {
  font-size: 20px;
  color: #12b504;
  margin-bottom: 0;
  font-weight: 700;
}
.info-head-payment .payment-info-head p {
  font-size: 12px;
  color: #2e2e2e;
  margin-bottom: 0;
}
.info-head-payment ul li {
  list-style: circle;
  font-size: 12px;
}

.info-head-payment-moreinfo {
  display: flex;
  padding: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background: #e3f8fa !important;
}
.info-head-payment-moreinfo h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}
.info-head-payment-moreinfo p {
  font-size: 14px;
}
.info-head-payment-moreinfo ul {
  margin: 0;
  padding: 0;
}
.info-head-payment-moreinfo ul li {
  font-size: 14px;
  margin-bottom: 8px;
  line-height: 16px;
}

.add-bagger {
  padding: 4px 4px !important;
  height: 33px !important;
  font-size: 11px !important;
  width: 122px !important;
  white-space: nowrap;
}

.email-booking {
  width: 100%;
  height: 70px;
}

.left-panal-payment {
  width: 100%;
  min-width: 230px;
}
.left-panal-payment form {
  width: 100%;
}

.plans {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  max-width: 100%;
  padding: 85px 50px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  border-radius: 20px;

  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  flex-direction: column;
  width: 100%;
}

.plans .plan input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.plans .plan {
  cursor: pointer;
  width: 100%;
}

.plans .plan .plan-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #e1e2e7;
  border-radius: 10px;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  -o-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  position: relative;
  padding: 5px 20px;
}

.plans .plan .plan-details h3 {
  margin-bottom: 10px;
  display: block;
  font-size: 20px;
  line-height: 24px;
  color: #252f42;
  margin: 0;
}
.plans .plan .plan-content i {
  margin-right: 15px;
}

.container .title {
  font-size: 16px;
  font-weight: 500;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  color: #252f42;
  margin-bottom: 20px;
}

.plans .plan .plan-details p {
  color: #646a79;
  font-size: 14px;
  line-height: 18px;
}

.plans .plan .plan-content:hover {
  -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
  box-shadow: 0px 3px 5px 0px #e8e8e8;
}

.plans .plan input[type="radio"]:checked + .plan-content:after {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  background: #216fe0;
  right: 20px;
  top: 13px;
  border-radius: 100%;
  border: 3px solid #fff;
  -webkit-box-shadow: 0px 0px 0px 2px #0066ff;
  box-shadow: 0px 0px 0px 2px #0066ff;
  display: none;
}

.plans .plan input[type="radio"]:checked + .plan-content {
  border: 2px solid #216ee0;
  background: #eaf1fe;
  -webkit-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
}

.bg-newone {
  background-image: url(./Image/product-bg.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.go_back_btn {
  margin-top: 20px;
}
.flight_not_found_pic {
  margin-bottom: 50px;
  width: 300px;
}

@media screen and (max-width: 991px) {
  .plans {
    margin: 0 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 40px;
  }

  .plans .plan {
    width: 100%;
  }

  .plan.complete-plan {
    margin-top: 20px;
  }

  .plans .plan .plan-content .plan-details {
    width: 70%;
    display: inline-block;
  }

  .plans .plan input[type="radio"]:checked + .plan-content:after {
    top: 45%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}
.design-one {
  padding: 20px;
}

.design-one .col-md-4cu {
  margin: 10px;
  margin: 10px;
  padding: 11px;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  display: flex;
  width: 20%;
  background: #fff;
  border-radius: 11px;
  margin: 9px;
  min-height: 116px;
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgb(72 107 255 / 10%);
  min-height: 111px;
  border-radius: 30px;
  background: #fff;
}
.design-one .col-md-4 img {
}

.padding-0 {
  padding: 0;
}

#gstinfocl {
  display: none;
}

@media screen and (max-width: 767px) {
  .plans .plan .plan-content .plan-details {
    width: 60%;
    display: inline-block;
  }
}

@media screen and (max-width: 540px) {
  .plans .plan input[type="radio"]:checked + .plan-content {
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }

  .plans .plan input[type="radio"]:checked + .plan-content:after {
    top: 13px;
    right: 10px;
  }

  .plans .plan .plan-content .plan-details {
    width: 100%;
  }

  .plans .plan .plan-content {
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
}

.icon-mobile-pay {
  font-size: 21px;
  margin-left: 9px;
}

.payment-details-paynow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.payment-details-paynow .text-size-lg {
  width: 100%;
  display: block;
}
.payment-details-paynow .buttonx {
  display: block;
  font-weight: bold;
  width: 100%;
  font-size: 20px;
}

/* inspiration */
.inspiration {
  font-size: 12px;
  margin-top: 50px;
  position: absolute;
  bottom: 10px;
  font-weight: 300;
}

.traveller-card {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #f15b2f;
}

.traveller-heading {
  background: #f15b2f;
  color: #fff;
}

.travel-email + .travel-email {
  display: none !important;
}

.inspiration a {
  color: #666;
}
@media screen and (max-width: 767px) {
  /* inspiration */
  .inspiration {
    display: none;
  }
}

.custom_radio {
  margin-bottom: 20px;
}
.custom_radio input[type="radio"] {
  display: none;
  font-size: 32px;
}
.custom_radio input[type="radio"] + label {
  position: relative;
  display: inline-block;
  padding-left: 1.5em;
  margin-right: 2em;
  cursor: pointer;
  line-height: 1em;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline;
}
.custom_radio input[type="radio"] + label:before,
.custom_radio input[type="radio"] + label:after {
  content: "";
  position: absolute;
  top: 0;
  left: -4px;
  width: 1.5em;
  height: 1.5em;
  text-align: center;
  color: white;
  font-family: Times;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.tab-add {
  font-size: 14px;
  color: #333;
}

.custom_radio input[type="radio"] + label:before {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em #d5d5d5;
}
.custom_radio input[type="radio"] + label:hover:before {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 0 0.3em white, inset 0 0 0 1em #c6c6c6;
}
.custom_radio input[type="radio"]:checked + label:before {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em rgba(255, 81, 0, 0.898);
  margin-right: 5px;
}

.round-trip2 {
  margin-left: -24px !important;
  display: block;
  width: 207px;
  margin-bottom: 10px;
}

.dataItem {
  font-weight: bold;
  font-size: 14px;
}

.oneway-tab {
  margin-right: 0px;
  margin-bottom: 20px;
}
.oneway-tab label {
  margin-left: 8px;
}

.roundway-rab {
  margin-right: 20px;
  margin-bottom: 20px;
}
.roundway-rab label {
  margin-left: 8px;
}

.animation {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 9rem;
  max-width: 55rem;
  background-color: #ffffff;
  /* border-radius: 10rem; */
  animation: 24s linear infinite sky;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}
.animation:after {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  box-shadow: 0 7rem 30rem -4rem #5aacdc inset;
}
.animation [class^="animation__"],
.animation [class*=" animation__"] {
  position: absolute;
}
.animation__plane {
  position: absolute;
  z-index: 1;
  top: calc(40% - (62px / 2));
  left: calc(50% - (272px / 2));
  width: 17rem;
  height: 6.2rem;
  animation: 2s ease-in-out takeOff, 5s ease-in-out infinite alternate flight 2s;
}
.animation__plane--shadow {
  bottom: 1rem;
  left: calc(54% - (8rem / 2));
  width: 8rem;
  height: 1rem;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.15);
  animation: 2s ease-in-out takeOffShadow,
    5s ease-in-out infinite alternate flightShadow 2s;
}
.animation__cloud--front {
  z-index: 2;
  top: 50%;
  left: 20%;
  width: 8.5rem;
  height: 3rem;
  fill: #cee4e7;
  animation: 4s linear infinite cloudFront;
}
.animation__cloud--middle {
  top: 22%;
  left: 75%;
  width: 6rem;
  height: 3rem;
  fill: #cee4e7;
  animation: 5s linear infinite cloudMiddle;
}
.animation__cloud--back {
  top: 6%;
  left: 34%;
  fill: #cee4e7;
  animation: 8s linear infinite cloudBack;
}
.animation .animation__loader {
  position: absolute;
  left: 0;
  bottom: 3rem;
  width: 100%;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.15);
}
.animation .animation__loader:after {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #cee4e7;
  overflow: hidden;
  animation: 1.5s ease-in-out infinite loader;
}

@keyframes loader {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
@keyframes takeOff {
  0% {
    transform: translate(-220%, 110%);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes takeOffShadow {
  0% {
    transform: translate(-440%, 110%);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 0.8;
  }
}
@keyframes flight {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, 10%);
  }
  75% {
    transform: translate(0, -10%);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes flightShadow {
  0% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  25% {
    transform: scale(0.9);
    opacity: 1;
  }
  75% {
    transform: scale(1.1);
    opacity: 0.6;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.8;
  }
}
@keyframes cloudFront {
  0% {
    transform: translate(520%, 0);
  }
  100% {
    transform: translate(-600%, 0);
  }
}
@keyframes cloudMiddle {
  0% {
    transform: translate(230%, 0);
  }
  100% {
    transform: translate(-900%, 0);
  }
}
@keyframes cloudBack {
  0% {
    transform: translate(910%, 0);
  }
  100% {
    transform: translate(-1000%, 0);
  }
}
@keyframes sky {
  0% {
    background-color: #ffffff;
  }
  20% {
    background-color: #ffffff;
  }
  35% {
    background-color: #ffc8bd;
  }
  50% {
    background-color: transparent;
  }
  70% {
    background-color: transparent;
  }
  85% {
    background-color: #ffc8bd;
  }
  100% {
    background-color: #ffffff;
  }
}

.loading {
  display: flex;
  justify-content: center;
}
.loading div {
  width: 1rem;
  height: 1rem;
  margin: 2rem 0.3rem;
  background: #979fd0;
  border-radius: 50%;
  -webkit-animation: 0.9s bounce infinite alternate;
  animation: 0.9s bounce infinite alternate;
}
.loading div:nth-child(2) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.loading div:nth-child(3) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@-webkit-keyframes bounce {
  to {
    opacity: 0.3;
    transform: translate3d(0, -1rem, 0);
  }
}

@keyframes bounce {
  to {
    opacity: 0.3;
    transform: translate3d(0, -1rem, 0);
  }
}

.loader {
  vertical-align: middle;
  text-align: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  max-width: 35rem;
  height: 100vh;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.iata_code {
  font-size: 6rem;
  opacity: 0.3;
  top: 52%;
  position: absolute;
  color: #0099cc;
}
.departure_city {
  left: 0;
}

.arrival_city {
  right: 1.5rem;
}

.plane {
  position: absolute;
  margin: 0 auto;
  width: 100%;
}

.plane-img {
  -webkit-animation: spin 2.5s linear infinite;
  -moz-animation: spin 2.5s linear infinite;
  animation: spin 3.5s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.earth-wrapper {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  padding-top: 2.7rem;
}

.width-40 {
  width: 40px;
  margin-right: 10px;
}
.earth {
  width: 160px;
  height: 160px;
  background: url("https://zupimages.net/up/19/34/6vlb.gif");
  border-radius: 100%;
  background-size: 340px;
  animation: earthAnim 12s infinite linear;
  margin: 0 auto;
  border: 1px solid #cdd1d3;
}

@keyframes earthAnim {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -340px;
  }
}

.logo-custom {
  width: 63%;
  margin-top: 100px;
  position: absolute;
  z-index: 1;
  margin: auto;
  left: 23%;
  margin-bottom: 20px;
  top: 106px !important;
}
.err_logo-custom {
  margin-bottom: 10px;
}

@media screen and (max-width: 420px) {
  .departure_city {
    left: 0;
    right: 0;
    top: 30%;
    position: absolute;
    margin: 0 auto;
  }

  .arrival_city {
    left: 0;
    right: 0;
    top: 93%;
    position: absolute;
    margin: 0 auto;
  }
}

/*New media queries for booking and review page start*/
.form-control-contact {
  font-size: 14px !important;
  box-shadow: none;
}
.grid_contact {
  background-color: #f5f7f8 !important;
  /* padding: 15px; */
}
.p_4_custome {
  padding: 10px 20px 10px 20px !important;
}
.form_group_cust {
  border-top: 1px solid #cdcecf;
  padding-top: 15px;
  margin-top: 15px;
}
.price_Changed {
  color: #f15b2f;
}
.sidenaV-Tab:active {
  border: 0;
  padding: 10px;
  padding-left: 35px;
  padding-right: 45px;
  font-size: 14px;
  border-radius: 5px;
}
.sidenaV-Tab {
  border: 0;
  padding: 10px;
  padding-left: 35px;
  padding-right: 45px;
  font-size: 14px;
  border-radius: 5px;
  background-color: transparent;
}
.copyright-small {
  font-size: 14px;
}
.background-contact {
  background-color: #f5f7f8;
}

.flight-details {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.text-travomint {
  color: #f14f20;
}

.airline-name-i img {
  width: 30px;
  margin-right: 15px;
}

.airline-name-i .codeName {
  font-weight: bold;
}

.layover-time {
  background: #fff;
  position: relative;
  z-index: 9;
  padding: 1px 6px;
  border: 1px dashed #333;
  left: 0;
}

.total-price {
  background: #fff;
  padding: 10px;
  font-weight: bold;
}

.line-dooted {
  border-bottom: 1px dashed #000;
  width: 100%;
  position: absolute;
  left: 0;
  top: 10px;
}

.border-left-de::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: -4px;
   border-left: 5px solid #12b504;
  /* margin: 20px 0; */
}

@media (min-width: 320px) and (max-width: 768px) {
  .payment-details-paynow .text-size-lg {
    text-align: center;
    margin-top: 15px;
  }

  .plans {
    margin: 0;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px;
  }

  .plans .plan .plan-details h3 {
    line-height: 18px;
    text-align: center;
  }
  .plans .plan .plan-content {
    padding: 6px 25px;

    text-align: center;
  }

  .plans .plan {
    width: 100%;
  }

  .payment-details .pr-4 {
    font-size: 14px;
  }
  .flex-dir {
    flex-direction: column;
  }

  .order1 {
    order: 1;
  }
  .order2 {
    order: 2;
  }

  .mobile-h3 {
    font-size: 13px !important;
  }
  .p-mobile {
    font-size: 13px;
  }
  .pricing-tag-mobile {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .plans .plan .plan-details h3 {
    margin-bottom: 10px;
    display: block;
    font-size: 19px !important;
    line-height: 24px;
    color: #000;
    margin: 0;
    text-align: left;
  }
}

table {
  width: 100%;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffa500;
  color: #ffa500;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffa500;
  color: #ffa500;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffa500;
  color: #ffa500;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}
.travellar-adult-css {
  margin-top: -10px;
}
.travel-p {
  margin-top: 10px;
}
@keyframes dotFlashing {
  0% {
    background-color: #ffa500;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}

.inner-button-new {
  display: flex;
  flex-direction: column;
}

.Travomint-wrapper {
  width: 100%;
  height: 100vh;
  background: #e9e9e9;
  height: 100vh;
}
.height_100vh {
  height: 100vh;
}

.text-size-lg {
  font-size: 30px;
}

.plans .plan input[type="radio"]:checked + .plan-content:after {
  border: 2px solid #216ee0;
  background: #eaf1fe;
  transition: ease-in 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.status-head {
  background-color: #d9ffe1;
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 0pc 0px 10px black;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  font-weight: 600;
  color: #0e5707;
  font-size: 15px;
}
.confirmation-bookingDetails {
  margin-top: 40px;
  font-size: medium;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.user-info-table-heading {
  background: transparent
    linear-gradient(276deg, rgba(255, 81, 0, 0.898), #e13535) 0 0 no-repeat;
  color: white;
}

.detail-user {
  font-size: 11px;
}

.detail-user1 {
  font-size: 12px;
}

.detail-user3 {
  font-size: 12px;
}

.whileload-redirect {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.whileload-inner {
  border: dashed 2px #f3f3f3;
  padding: 40px 30px 50px;
  border-radius: 20px;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  box-shadow: 0 0 50px rgb(0 0 0 / 5%);
}
nav.navbar.bg-unique {
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-shadow: 0 -4px 11px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 -4px 11px rgb(0 0 0 / 20%);
  -ms-box-shadow: 0 -4px 11px rgb(0 0 0 / 20%);
  -o-box-shadow: 0 -4px 11px rgb(0 0 0 / 20%);
  box-shadow: 0 -4px 11px rgb(0 0 0 / 20%);
}
.btn.btn-phoneheader {
  height: auto;
  padding: 10px 15px;
  line-height: normal;
  font-weight: 600;
  color: #474a4c;
  -webkit-animation: glowing 1s infinite;
  -moz-animation: glowing 1s infinite;
  -o-animation: glowing 1s infinite;
  animation: glowing 1s infinite;
  color: #fff;
  position: relative;
  z-index: 2;
  border-radius: 6px;
  background: #23395d;
  font-size: 14px;
  vertical-align: middle;
  box-shadow: none;
  min-width: 189px;
  letter-spacing: 0.6px;
  margin: 0;
  position: relative;
  overflow: hidden;
}

@-webkit-keyframes glowing {
  0% {
    background-color: #c41e2b;
  }

  30% {
    background-color: #23395d;
  }

  50% {
    background-color: #f25b29;
  }

  to {
    background-color: #c41e2b;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #c41e2b;
  }

  30% {
    background-color: #23395d;
  }

  50% {
    background-color: #f25b29;
  }

  to {
    background-color: #c41e2b;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #c41e2b;
  }

  30% {
    background-color: #23395d;
  }

  50% {
    background-color: #f25b29;
  }

  to {
    background-color: #c41e2b;
  }
}

@keyframes glowing {
  0% {
    background-color: #c41e2b;
  }

  30% {
    background-color: #23395d;
  }

  50% {
    background-color: #f25b29;
  }

  to {
    background-color: #c41e2b;
  }
}
.btn.btn-phoneheader .fa-headset {
  font-size: 24px;
}
.btn.btn-phoneheader:hover,
.btn.btn-phoneheader:active {
  color: #fff;
  box-shadow: none;
}
.btn.btn-phoneheader:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 0;
  top: 50%;
  transform: translateX(0) translateY(-50%);
  -o-transform: translateX(0) translateY(-50%);
  -moz-transform: translateX(0) translateY(-50%);
  -ms-transform: translateX(0) translateY(-50%);
  -webkit-transform: translateX(0) translateY(-50%);
  display: block;
  width: 45px;
  height: 45px;
  background: #ddd;
  border-radius: 50%;
  animation: pulse-border 1.5s ease-out infinite;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    -o-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    -moz-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    -ms-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 0.3;
  }

  to {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(16.74);
    -o-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(16.74);
    -moz-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(16.74);
    -ms-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(16.74);
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(16.74);
    opacity: 0;
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .detail-user1 {
    white-space: nowrap;
  }
  .detail-user {
    white-space: nowrap;
  }

  #scrollMax {
    width: 100%;
    overflow: scroll;
    padding-bottom: 15px;
  }

  .user-info-popup {
    padding-right: 10px !important;
  }
}

.text-input-all div h5,
h6 {
  font-size: 14px;
}

.needs-validation .modal-body {
  box-shadow: rgb(191 195 207) 3px 3px 6px inset,
    rgb(255 255 255) -3px -3px 6px inset;
}

.input-main-one div h5 {
  font-size: 14px;
  font-weight: bold;
}

.flexEnd {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.input-main-one {
  margin-bottom: 20px;
}
input-main-one + .input-main-one {
  border-top: 1px solid #e5e5e5;
}

.text-input-all input {
  box-shadow: none !important;
}
.text-input-all select {
  box-shadow: none !important;
}
.form-group div.text-input-all {
  margin-top: 20px !important;
}

h5.main-heading {
  font-size: 20px;
  color: #f15b2f;
}

.text-input-al {
  margin-bottom: 10px;
}

/* .input-main-one h5{ font-size:22px; font-weight: bold; color:#e13535;    } */

.price-linethrough {
  position: relative;
  color: #8d8d8d;
  font-weight: 500;
}

.price-linethrough::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 50%;
  border-top: 2px solid #ff3547;
  border-right-color: #ff3547;
  border-bottom-color: #ff3547;
  border-left-color: #ff3547;
  width: 100%;
  transform: rotate(-8deg);
  -ms-transform: rotate(-8deg);
  -o-transform: rotate(-8deg);
  -moz-transform: rotate(-8deg);
  -webkit-transform: rotate(-8deg);
}
.row-tprice {
  margin: 0 -10px;
  box-shadow: -12px 0 0 #fff, 12px 0 0 #fff;
}
.ratingapp-box {
  background: #f5f7f8;
  padding: 13px 11px;
  border-radius: 10px;
  box-shadow: 0 11px 7px -10px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 0 11px 7px -10px rgb(0 0 0 / 10%);
  max-width: 416px;
  margin-left: auto;
  margin-right: auto;
}
.ratingapp-box img{
  image-rendering: -webkit-optimize-contrast;
}

 
.ratingapp-box hr {
  background: transparent;
  border-bottom: solid 1px #b9b9b9;
  height: auto;
  margin: 18px 0;
}

.ratingapp-box .row.align-items-end {
  margin: 0 -5px;
}
.ratingapp-box .row.align-items-end .col-6 {
  padding: 0 5px;
}

.ratingapp-box .row.align-items-end .col-6 img{
  height: 57.59px
}

@media (max-width: 767px) {
  .btn.btn-phoneheader {
    padding: 9px 13px;
    min-width: 180px;
  }
}
@media (max-width: 575px) {
  .btn.btn-phoneheader {
    font-size: 12px;
    padding: 6px 10px;
    min-width: auto;
  }
  .btn.btn-phoneheader .fa-headset {
    font-size: 20px;
  }
  nav.navbar.bg-unique .col-span-4.ml-2 img {
    width: 108px;
  }
}
